import React, {useEffect, useState} from 'react';
import './dashboard.scss';
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {currencyFormat, getIsMobile, getProduct, getProgress, groupEveryTwo} from "../../Services/helpers";
import ProgressBar from "../../Components/ProgressBar";
import Table from "../../Components/Table";
import {FaCheck} from "react-icons/fa";
import {FaChevronRight, FaClockRotateLeft, FaMagnifyingGlass, FaX} from "react-icons/fa6";
import {Button, CircularProgress, FormGroup, IconButton, Modal, Tab, Tabs, TextField, Tooltip} from "@mui/material";
import {setAlert} from "../../AppSlice";
import {Box} from "@mui/system";
import {ThreeDots} from "react-loader-spinner";

const tableColumns = [
    {
        name: 'Membro',
        key: 'name'
    },
    {
        name: 'Enviou',
        key: 'sent'
    }
]

const goalsTableColumns = [
    {
        name: "Produto",
        key: 'name'
    },
    {
        name: 'Objetivo',
        key: 'goal'
    },
    {
        name: 'Realizado',
        key: 'current'
    },
    {
        name: 'Defasagem',
        key: 'difference'
    },
    {
        name: 'Progresso',
        key: 'progress'
    }
]

const Dashboard = () => {
    const { productions, userRole, teamsEmptyProductionFlags } = useSelector(state => state.app)
    const { products } = useSelector(state => state.products)
    const { members } = useSelector(state => state.members)
    const { goals } = useSelector(state => state.goals)
    const { sectors } = useSelector(state => state.sectors)
    const [goalsData, setGoalsData] = useState(false)
    const [todaysProduction, setTodaysProduction] = useState(false)
    const [productDetails, setProductDetails] = useState(false)

    const tableColumnsProduction = [
        {name: 'Produto', key: 'name'},
        {name: 'Total', key: 'total'},
        {name: 'Varejo', key: 'varejo'},
        {name: 'Prime', key: 'prime'}
    ]

    if (userRole === "admin") {
        tableColumnsProduction.push(
            {name: 'Envios', key: 'details'}
        )
    }

    useEffect(() => {
        if (!productions || !sectors || !products) return

        const result = {}

        products.forEach(product => {
            result[product.id] = {total: 0, varejo: 0, prime: 0}
            const productProductions = productions.filter(production => {
                return production.product === product.id &&
                    dayjs().isSame(production.date, 'date')
            })
            productProductions.forEach(production => {
                const sector = sectors.find(sector => sector.id === production.sector)
                const isPrime = sector && sector.is_prime
                if (result[product.id]) {
                    result[product.id]['total'] += Number(production.amount)
                    if (isPrime) {
                        result[product.id]['prime'] += Number(production.amount)
                    } else {
                        result[product.id]['varejo'] += Number(production.amount)
                    }
                }
            })
        })

        setTodaysProduction(result)
    }, [productions]);

    useEffect(() => {
        if (!goals || !productions || !products) return

        const result = {}

        products.forEach(product => {
            result[product.id] = {
                target: 0,
                current: 0
            }
        })

        goals.filter(goal => goal.month === dayjs().month() + 1 && goal.year === dayjs().year())
            .forEach(goal => {
                if (goal.product in result) {
                    result[goal.product]['target'] += Number(goal.amount)
                } else {
                    result[goal.product] = {target: Number(goal.amount)}
                }
            })

        productions.filter(production => dayjs(production.date).month() === dayjs().month() && dayjs(production.date).year() === dayjs().year())
            .forEach(production => {
                if(result[production.product]) {
                    result[production.product]['current'] += Number(production.amount)
                } else {
                    result[production.product] = {
                        current: Number(production.amount)
                    }
                }
            })

        setGoalsData(result)
    }, [goals, productions]);

    const isMobile = getIsMobile();

    const todaysProductionBody = () => {
        console.log("TRODS", products, todaysProduction)
        return <>
            <div className={"SectionTitle"}>
                <span>Produção de Hoje</span>
                <CopyProduction/>
            </div>
            <div className={"Container"}>
                { products && todaysProduction && <Table
                    compact
                    columns={tableColumnsProduction}
                    // actions={tableActionsProduction}
                    data={products.map(product => {
                        if (!todaysProduction[product.id]) return
                        const production = todaysProduction[product.id]
                        const isCurrency = product.type === "V"
                        return {
                            name: product.name,
                            total: isCurrency ? currencyFormat(production['total']) : production['total'],
                            prime: isCurrency ? currencyFormat(production['prime']) : production['prime'],
                            varejo: isCurrency ? currencyFormat(production['varejo']) : production['varejo'],
                            // prime: currencyFormat(todaysProduction[product.id]? todaysProduction[product.id]['prime'] : 0),
                            // varejo: currencyFormat(todaysProduction[product.id]? todaysProduction[product.id]['varejo'] : 0),
                            details: <div
                                style={{
                                    backgroundColor: "#3f0b0e",
                                    width: 30,
                                    height: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 5,
                                    cursor: 'pointer'
                                }}
                                onClick={() => setProductDetails(product)}
                            >
                                <FaMagnifyingGlass color={"white"}/>
                            </div>
                        }
                    })}
                />
                }
                {/*{*/}
                {/*    products && todaysProduction && products.map(product => {*/}
                {/*        return <div className={"Card"} onClick={() => setProductDetails(product)}>*/}
                {/*            <div className={"Name"}>*/}
                {/*                <span>*/}
                {/*                    {product.name}*/}
                {/*                </span>*/}
                {/*                <FaChevronRight/>*/}
                {/*            </div>*/}
                {/*            <div className={"Value"}>*/}
                {/*                {currencyFormat(todaysProduction[product.id] || 0)}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    })*/}
                {/*}*/}
            </div>
        </>
    }

    const columnsDetails = [
        {
            name: "Membro",
            key: 'member'
        },
        {
            name: "Agência",
            key: 'sector'
        },
        {
            name: "Valor",
            key: 'amount'
        }
    ]

    const tableDataDetails = productions.filter(production => {
        console.log("PRODUCTIONS DETAILS", production.product, productDetails.id)
        return production.product === productDetails.id && dayjs().isSame(production.date, 'date')
    }).map(production => {
        const member = members.find(member => member.id === production.member)
        const sector = sectors.find(sector => sector.id === production.sector)
        return {
            sector: sector.name,
            member: member.name,
            amount: currencyFormat(Number(production.amount))
        }
    })

    const goalsTableData = Object.keys(goalsData).map(product => {
        const productData = getProduct(product)
        if (!productData) return {name: '', goal: '', current: '', difference: '', progress: ''}
        return {
            name: productData.name,
            goal: currencyFormat(goalsData[product]['target']),
            current: currencyFormat(goalsData[product]['current']),
            difference: currencyFormat(goalsData[product]['target'] - goalsData[product]['current']),
            progress: <ProgressBar
                completed={getProgress(goalsData[product]['target'], goalsData[product]['current'])}
                compact
            />
        }
    })

    return (
        <div className={"DashboardContainer"}>
            {
                isMobile ? <>
                    <div className={"Section"}>
                        {todaysProductionBody()}
                    </div>
                    { userRole === "admin" && <div className={"Section"}>
                        {<HasSentToday/>}
                    </div>}
                </> : <>
                        <div className={"Section"} style={userRole === "user" ? {height: '100%'} : {}}>
                            {todaysProductionBody()}
                        </div>
                </>
            }
            <div className={"Section"}>
                <div className={"SectionTitle"}>
                    Objetivos do Mês
                </div>
                <div className={"Container"}>
                    {
                        products && goalsData && <Table
                            data={goalsTableData}
                            columns={goalsTableColumns}
                            compact
                            firstRowSticky
                        />
                    }
                </div>
            </div>
            <Modal  open={!!productDetails} onClose={() => setProductDetails(false)}>
                <>
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: getIsMobile() ? '90%' : '60%', maxWidth: '90%', bgcolor: 'white', p:1 ,  boxShadow: 24 }}>
                        <h2>Produções de {productDetails.name} de hoje</h2>
                        <div style={{maxHeight: '40dvh', overflowY: 'auto', marginBottom: 15}}>
                            {
                                <Table data={tableDataDetails} columns={columnsDetails} compact/>
                            }
                        </div>
                        <Button variant="contained" onClick={() => setProductDetails(false)}>Voltar</Button>
                    </Box>
                </>
            </Modal>
        </div>
    );
};

export default Dashboard;


export const CopyProduction = () => {
    const { productions, selectedTeam, userRole } = useSelector(state => state.app)
    const { products } = useSelector(state => state.products)
    const { sectors } = useSelector(state => state.sectors)
    const [todaysProduction, setTodaysProduction] = useState(false);
    const dispatch = useDispatch();

    const copyMelina = () => {
        const text = `Produção: Melina 
Auto: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 59).reduce((acc, x) => acc += Number(x.amount), 0))}
RE: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 60).reduce((acc, x) => acc += Number(x.amount), 0))}
Pré Formtd: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 63).reduce((acc, x) => acc += Number(x.amount), 0))}
Sob Medida: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 68).reduce((acc, x) => acc += Number(x.amount), 0))}
Grandes Riscos: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 69).reduce((acc, x) => acc += Number(x.amount), 0))}
SAÚDE: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 67).reduce((acc, x) => acc += Number(x.amount), 0))}
DT. PF: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 61).reduce((acc, x) => acc += Number(x.amount), 0))}
DT. PJ: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 62).reduce((acc, x) => acc += Number(x.amount), 0))}
Vida A/U: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 64).reduce((acc, x) => acc += Number(x.amount), 0))}
Vida Mensal: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 65).reduce((acc, x) => acc += Number(x.amount), 0))}
Portabilidade: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 66).reduce((acc, x) => acc += Number(x.amount), 0))}
Agenda: ${productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 70).reduce((acc, x) => acc += Number(x.amount), 0)}`
        navigator.clipboard.writeText(text)
        dispatch(setAlert({message: "Produção copiada para a área de transferência", severity: "success"}))
    }

    const copyElvis = () => {
        const text = `Produção: Elvis
Auto: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 41).reduce((acc, x) => acc += Number(x.amount), 0))}
RE: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 42).reduce((acc, x) => acc += Number(x.amount), 0))}
Pré Formtd: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 46).reduce((acc, x) => acc += Number(x.amount), 0))}
Sob Medida: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 51).reduce((acc, x) => acc += Number(x.amount), 0))}
Grandes Riscos: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 57).reduce((acc, x) => acc += Number(x.amount), 0))}
SAÚDE: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 50).reduce((acc, x) => acc += Number(x.amount), 0))}
DT. PF: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 43).reduce((acc, x) => acc += Number(x.amount), 0))}
DT. PJ: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 44).reduce((acc, x) => acc += Number(x.amount), 0))}
Vida A/U: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 47).reduce((acc, x) => acc += Number(x.amount), 0))}
Vida Mensal: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 48).reduce((acc, x) => acc += Number(x.amount), 0))}
Portabilidade: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 49).reduce((acc, x) => acc += Number(x.amount), 0))}
Agenda: ${productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 58).reduce((acc, x) => acc += Number(x.amount), 0)}`
        navigator.clipboard.writeText(text)
        dispatch(setAlert({message: "Produção copiada para a área de transferência", severity: "success"}))
    }

    const copySuprema = () => {
        console.log("PROgfrD", productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 14))
        const text = `PRODUÇÃO ${dayjs().format("DD/MM/YYYY")}

MASTER: ALISSON FELIPE
 
🟠 V A R E J O:


▶ RES. PRÉ-F: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 10 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ RES. SOBM:  ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 11 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ AUTO: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 8 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ RE: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 9 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ GRD RISCOS: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 18 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ SAÚDE: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 12 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ DENTAL PF: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 13 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ DENTAL PJ: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 14 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ VIDA A/U: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 15 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ VIDA MENSAL: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 16 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ PORT PREV (QT): ${productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 17 && !(sectors.find(sector => production.sector === sector.id).is_prime)).length}
▶ PORT PREV (R$): ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 17 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ PENSÃO TEMP(R$): ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 19 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}


⚫ P R I M E:

▶ RES. PRÉ-F: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 10 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ RES. SOBM:  ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 11 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ AUTO: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 8 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ RE: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 9 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ GRD RISCOS: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 18 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ DENTAL PF: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 13 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ VIDA A/U: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 15 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ VIDA MENSAL: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 16 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ PORT PREV (QT): ${productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 17 && (sectors.find(sector => production.sector === sector.id).is_prime)).length}
▶ PORT PREV (R$): ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 17 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ PENSÃO TEMP(R$): ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 19 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}`

        navigator.clipboard.writeText(text)
        dispatch(setAlert({message: "Produção copiada para a área de transferência", severity: "success"}))
    }

    const copyArretados = () => {
        const text = `PRODUÇÃO ${dayjs().format("DD/MM/YYYY")}

MASTER: IGOR
 
🟠 V A R E J O:


▶ RES. PRÉ-F: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 20 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ RES. SOBM:  ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 25 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ AUTO: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 26 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ RE: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 34 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ GRD RISCOS: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 27 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ SAÚDE: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 33 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ DENTAL PF: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 32 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ DENTAL PJ: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 24 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ VIDA A/U: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 22 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ VIDA MENSAL: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 21 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ PORT PREV (QT): ${productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 31 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0)}
▶ PORT PREV (R$): ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 30 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ PENSÃO TEMP(R$): ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 29 && !(sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}


⚫ P R I M E:

▶ RES. PRÉ-F: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 20 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ RES. SOBM:  ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 25 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ AUTO: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 26 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ RE: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 34 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ GRD RISCOS: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 27 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ DENTAL PF: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 32 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ VIDA A/U: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 22 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ VIDA MENSAL: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 21 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ PORT PREV (QT): ${productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 31 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0)}
▶ PORT PREV (R$): ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 30 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}
▶ PENSÃO TEMP(R$): ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && Number(production.amount) > 0 && production.product === 29 && (sectors.find(sector => production.sector === sector.id).is_prime)).reduce((acc, x) => acc += Number(x.amount), 0))}`

        navigator.clipboard.writeText(text)
        dispatch(setAlert({message: "Produção copiada para a área de transferência", severity: "success"}))
    }

    const copyEunice = () => {
        const text = `Auto: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 144).reduce((acc, x) => acc += Number(x.amount), 0))}
RE: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 145).reduce((acc, x) => acc += Number(x.amount), 0))}
Pré Formatado: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 146).reduce((acc, x) => acc += Number(x.amount), 0))}
Sob Medida: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 147).reduce((acc, x) => acc += Number(x.amount), 0))}
Grandes Riscos: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 148).reduce((acc, x) => acc += Number(x.amount), 0))}
Saúde: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 149).reduce((acc, x) => acc += Number(x.amount), 0))}
Dental PJ: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 151).reduce((acc, x) => acc += Number(x.amount), 0))}
Dental PF: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 150).reduce((acc, x) => acc += Number(x.amount), 0))}
Vida único: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 152).reduce((acc, x) => acc += Number(x.amount), 0))}
Vida mensal: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 154).reduce((acc, x) => acc += Number(x.amount), 0))}
Pecúlio de prev.: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 197).reduce((acc, x) => acc += Number(x.amount), 0))}
Portabilidade: ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 159).reduce((acc, x) => acc += Number(x.amount), 0))}
Cotação Saúde: ${productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 191).reduce((acc, x) => acc += Number(x.amount), 0)}
Cotação Auto: ${productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 192).reduce((acc, x) => acc += Number(x.amount), 0)}
Ofertas Dental PJ: ${productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 193).reduce((acc, x) => acc += Number(x.amount), 0)}

Agenda: `
        navigator.clipboard.writeText(text)
        dispatch(setAlert({message: "Produção copiada para a área de transferência", severity: "success"}))
    }

    const copyMarco = () => {
        const text = `Produção: Marco Andrei
Auto :   ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 130).reduce((acc, x) => acc += Number(x.amount), 0))}
RE :   ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 132).reduce((acc, x) => acc += Number(x.amount), 0))}
Pré Formtd : ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 131).reduce((acc, x) => acc += Number(x.amount), 0))}
Sob Medida :   ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 133).reduce((acc, x) => acc += Number(x.amount), 0))}
Saúde : ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 137).reduce((acc, x) => acc += Number(x.amount), 0))}
DT. PF : ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 129).reduce((acc, x) => acc += Number(x.amount), 0))}
DT. PJ : ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 135).reduce((acc, x) => acc += Number(x.amount), 0))}
Vida A/U : ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 134).reduce((acc, x) => acc += Number(x.amount), 0))}
Vida Mensal :   ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 127).reduce((acc, x) => acc += Number(x.amount), 0))}
Agenda:`
        navigator.clipboard.writeText(text)
        dispatch(setAlert({message: "Produção copiada para a área de transferência", severity: "success"}))
    }

    const copySuperacao = () => {
        const text = `Produção: Maria Amélia
AUTOMOVEL   : ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 115).reduce((acc, x) => acc += Number(x.amount), 0))}
RE EMPRESARIAL :   ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 116).reduce((acc, x) => acc += Number(x.amount), 0))}
PRE FORMATADO :   ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 119).reduce((acc, x) => acc += Number(x.amount), 0))}
SOB MEDIDA : ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 124).reduce((acc, x) => acc += Number(x.amount), 0))}
DENTAL PF :   ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 117).reduce((acc, x) => acc += Number(x.amount), 0))}
DENTAL PJ : ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 118).reduce((acc, x) => acc += Number(x.amount), 0))}
SAUDE : ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 123).reduce((acc, x) => acc += Number(x.amount), 0))}
VIDA UNICO : ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 120).reduce((acc, x) => acc += Number(x.amount), 0))}
VIDA MENSAL : ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 121).reduce((acc, x) => acc += Number(x.amount), 0))}
SWISS RE :   ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 160).reduce((acc, x) => acc += Number(x.amount), 0))}
PORTABILIDADE :   ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 122).reduce((acc, x) => acc += Number(x.amount), 0))}
PREVIDENCIA :   ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 161).reduce((acc, x) => acc += Number(x.amount), 0))}
AGENDAMENTO DE SAUDE :   ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 162).reduce((acc, x) => acc += Number(x.amount), 0))}
AGENDAMENTO AUTO :   ${currencyFormat(productions.filter(production => dayjs().isSame(production.date, 'date') && production.product === 163).reduce((acc, x) => acc += Number(x.amount), 0))}`
        navigator.clipboard.writeText(text)
        dispatch(setAlert({message: "Produção copiada para a área de transferência", severity: "success"}))
    }


    const handleCopyProduction = () => {
        if (selectedTeam.name === "Suprema" || selectedTeam.name === "Masterprod") {
            return copySuprema()
        }
        if (selectedTeam.name === 'Arretados') {
            return copyArretados()
        }
        if (selectedTeam.name === 'Elvis') {
            return copyElvis()
        }
        if (selectedTeam.name === "Elite Sul") {
            return copyMelina()
        }

        if (selectedTeam.name === "Objetiva") {
            return copyEunice()
        }

        if (selectedTeam.name === "Marco Andrei") {
            return copyMarco()
        }

        if (selectedTeam.name === "Superação") {
            return copySuperacao()
        }

        let text = `Produção Equipe ${selectedTeam.name}`
        Object.keys(todaysProduction).map(productId => {
            const product = products.find(product => product.id === Number(productId))
            text += `\n${product.name}: ${product.type === "V" ? "R$ " : ""} ${todaysProduction[productId]}`
        })

        dispatch(setAlert({message: "Produção copiada para a área de transferência", severity: "success"}))

        navigator.clipboard.writeText(text)
    }

    useEffect(() => {
        if (!productions) return

        const result = {}

        productions.filter(production => dayjs().isSame(production.date, 'date'))
            .forEach(production => {
                console.log("PRODUCTION", production.product, Object.keys(result).map(product => Number(product)).includes(production.product))
                if (Object.keys(result).map(product => Number(product)).includes(production.product)) {
                    result[production.product] += Number(production.amount)
                } else {
                    result[production.product] = Number(production.amount)
                }
            })

        setTodaysProduction(result)
    }, [productions]);

    return <Button variant={'contained'} onClick={handleCopyProduction} style={{width: 150, backgroundColor: '#0B3F3C', fontSize: 12, padding: "5px 2px"}}>
        Copiar Produção
    </Button>
}

export function HasSentToday({container}) {
    const [isOpen, setIsOpen] = useState(false)

    const { productions, teamsEmptyProductionFlags } = useSelector(state => state.app)
    const { members } = useSelector(state => state.members)

    const tableData = members && teamsEmptyProductionFlags && members.map(member => {
        const hasFlag = teamsEmptyProductionFlags.find(flag => dayjs().isSame(flag.date, 'date') && flag.member === member.id)
        return {
            name: member.name,
            sent: hasFlag ? <span>ZEROU</span> : !!productions.find(production => dayjs().isSame(production.date, 'date') && production.member === member.id) ?
                <FaCheck color={"green"}/> : <FaX color={"red"}/>
        }
    }).sort((a, b) => {
        // First, compare the `sent` property
        if (a.sent < b.sent) return -1;
        if (a.sent < b.sent) return -1;
        if (a.sent > b.sent) return 1;

        // If `sent` properties are equal, compare the `name` property
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        // If both `sent` and `name` properties are equal
        return 0;
    })

    if (container) {
        return <>
            <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={{backdropFilter: "blur(2px)"}}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    maxWidth: '90%',
                    bgcolor: 'white',
                    boxShadow: 24,
                    // border: '3px solid red',
                    padding: 1,
                    // boxSizing: 'border-box',
                }}>
                    <div className={"SectionTitle"}>
                        Controle de Envio
                    </div>
                    <div className={"Container"} style={{maxHeight: 500}}>
                        {
                            tableData ? <Table compact={true} columns={tableColumns} data={tableData}/> : <CircularProgress/>
                        }
                    </div>
                </Box>
            </Modal>
            <Button className={"HeaderActionButton"} variant={'contained'} onClick={() => setIsOpen(true)}>
                <Tooltip title={"Ver quem enviou ou não hoje"}>
                    Controle de Envio
                </Tooltip>
            </Button>
        </>
    }

    return  <>
        {/*<Modal open={isOpen} onClose={() => setIsOpen(false)} sx={{backdropFilter: "blur(2px)"}}>*/}
        {/*    <Box sx={{*/}
        {/*        position: 'absolute',*/}
        {/*        top: '50%',*/}
        {/*        left: '50%',*/}
        {/*        transform: 'translate(-50%, -50%)',*/}
        {/*        width: 800,*/}
        {/*        maxWidth: '90%',*/}
        {/*        bgcolor: 'white',*/}
        {/*        boxShadow: 24,*/}
        {/*        // border: '3px solid red',*/}
        {/*        padding: 1,*/}
        {/*        // boxSizing: 'border-box',*/}
        {/*    }}>*/}
                <div className={"SectionTitle"}>
                    Controle de Envio
                </div>
                <div className={"Container"} style={{maxHeight: 500}}>
                    {
                        tableData ? <Table compact={true} columns={tableColumns} data={tableData}/> : <CircularProgress/>
                    }
                </div>
            {/*</Box>*/}
        {/*</Modal>*/}
        {/*<Button className={"HeaderActionButton"} variant={'contained'} onClick={() => setIsOpen(true)}>*/}
        {/*    <Tooltip title={"Ver quem enviou ou não hoje"}>*/}
        {/*        Controle de Envio*/}
        {/*    </Tooltip>*/}
        {/*</Button>*/}
    </>
}
